import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import DigitalMarketingServiceBanner from '../images/digital_marketing_service_banner.svg'
import DigitalMarketingServiceFeature from '../images/digital_marketing_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const DigitalMarketing = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/digital-marketing" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Digital Marketing Services" description='eWise - Digital Marketing Services'/>
<section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-55 2xl:text-60 block">Digital Marketing</strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-34 xl:text-34 2xl:text-50"></span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="md:mt-12 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 pt-12 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto lg:max-w-full -mr-16 max-w-500 md:m-auto lg:m-0 lazyload" loading="lazy" data-src={DigitalMarketingServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Banner"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Banner"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">eWise is a digital agency that loves creating success stories through their business partners<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p>eWise takes a strategic, data driven and user focused approach to digital marketing, enabling you to reach the right
                audience and tailored to deliver the results you need.</p>
            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-3/5 px-4 order-2 lg:order-1">
                  
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise is more than a digital marketing agency – an in-house digital partner<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>

                    <div className="features_list_left w-full pb-6">
                        <ul className="list_features">
                            <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Having a digital marketing agency that holistically understands your business goals enables you to focus on main
                            objectives, with the assurance that your marketing is in safe hands.</li>
                            <li className="pl-10 relative">Whether you are looking to enhance the visibility of your website in search, want to increase customer engagement
                            through social media and email or more broadly, want to boost the revenue through your website, you’re in the right
                            place.</li>
                        </ul>
                    </div>
                    <div className="features_list_left w-full">
                        <h5 className="pb-4 text-lg md:text-xl lg:text-22">Integration, Strategy, Results</h5>
                        <ul className="list_features">
                            <li className=" pl-10 relative">Benefit from end-to-end services covering the comprehensive digital marketing spectrum, integrated in strategies to meet
                            your commercial digital marketing objectives.</li>
                        </ul>
                    </div>
                </div>
                <div className="features_right w-full lg:w-2/5 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-330 md:max-w-470 xl:max-w-full mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={DigitalMarketingServiceFeature} alt="iphone"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise Digital Marketing Services<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Customizing digital marketing around your business goals</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Establishing a way forward for your digital presence, whilst meeting user requirements and business and sales objectives
                        requires a holistic approach. An approach that brings together various marketing channels in a single strategy that
                        boosts the growth of your business.</li>
                        <li className="pl-10 relative">By developing a strategic vision for digital marketing projects based on your business objectives and the needs of your
                        target audience, our digital marketing consultants are able to plan and execute multichannel, measurable strategies.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Digital strategy that guides your business from start to end</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">Planning and applying digital strategy requires a holistic approach that reviews every aspect of your business and
                        current digital presence. Thorough research and analysis follow, ensuring that opportunity is clearly defined from the
                        outset.</li>
                        <li className="pl-10 relative">With the delivery of a futuristic vision and marketing plan, you can then understand exactly where your business needs
                        to be heading to offer a rich user experience and develop a website that generates better ROI.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Digital campaign planning – campaigns designed to grow your business</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">eWise’s expert team of digital marketers can help you achieve your business objectives with our wide range of digital
                        management services.</li>
                        <li className="pl-10 relative">We assure you to design a holistic digital marketing campaign structure that will be unique in the market competition
                        demands with our deep understanding of how different social platforms interact, work together and support each other.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">The power of content marketing</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">With us, the experienced marketers, content can be customized to engage your target audience at every phase of their
                        buyer’s journey. From research, to planning, to implementation – eWise can deliver efficient, effective content
                        marketing.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Our objectives for you!</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">To attract prospects and create awareness of your brand</li>
                        <li className="pl-10 relative mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4">To engage them to make them familiar with your brand, and think positively about it</li>
                        <li className="pl-10 relative">To influence them into persuading them to take the desired action</li>
                    </ul>
                </div>
                <div className="features_list_left w-full px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Search Engine Optimization – SEO</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise’s SEO specialists can team up with you to deliver measurable, meaningful results and achieve optimal search
                        engine success! We offer you a much needed approach that focuses on your user and is based only on White Hat search
                        engine optimization strategies.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">PPC Management – Pay per Click Advertising</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Our pay per click advertising approach is all about reaching your target audience and guiding them through the
                        conversion process. We believe that we can make a huge difference in your sales funnel through paid ads. By customizing
                        your PPC ads, and using highly relevant landing pages, we enable you to drive as many conversions as possible within
                        your PPC budget.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full px-4 pb-6">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Social Media Marketing</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">Whether a large or small business, our digital and social media marketing specialists can help you to analyze, develop
                        and manage powerful and profitable social media advertising campaigns. Our deep understanding of various social media
                        platforms makes it easier for us to create a social media strategy that will work for a specific business.</li>
                    </ul>
                </div>
                <div className="features_list_left w-full px-4">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Display Advertising</h5>
                    <ul className="list_features">
                        <li className="pl-10 relative">eWise’s transparent approach to display marketing simplifies this complex landscape of programmatic advertising and
                        ensures accurate and consistent display advertising campaign performance reports. We assure you to give your brand the
                        power to serve customized and relevant display ads and ad copy to the right people, at the right time.
                        </li>
                    </ul>
                </div>
            </div>

            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why hire eWise as a
                        Digital Marketing Expert<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span>
                    </h4>
                    <p className="px-4 pb-4">eWise has been in the digital marketing space for years! Our dedicated team of experts is
                        well-versed with the working
                        of digital platforms and media. The comprehensive and personalized approach makes sure our each and every
                        client makes a
                        mark and sets itself apart in the industry!</p>
                    <p className="px-4">We promise to deliver your business goals through our accuracy and expertise!</p>
                </div>
            </div>

          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Service Circle"/>
  </section>
    </Layout>
  )
}

export default DigitalMarketing